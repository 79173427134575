import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./index.scss"
import 'animate.css';
import "./swiper.css";
Vue.config.productionTip = false
import "./utils/rem.js"

import moment from "moment"
Vue.prototype.$moment = moment

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import url from "@/utils/url"
Vue.prototype.$url = url
import Footer from "@/components/Footer"
import Header from "@/components/Header"
import Pagination from "@/components/Pagination"
import { msg } from './utils/utils'
Vue.component('Footer',Footer)
Vue.component('Header',Header)
Vue.component('Pagination',Pagination)
Vue.prototype.msg = msg

import {webRecordLog} from "@/api/news"
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  // if (to.meta.title) {
  //   document.title = to.meta.title +' - 金沙贵宾会集团官网'
  // }else{
  //   document.title = to.meta.title
  // }
  let query={
    name:'visit'
  }
  try {
    webRecordLog(query)
  } catch (error) {
    
  }
  
  next()
 })
//  router.afterEach(()=>{})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
