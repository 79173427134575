<template>
  <div class="navbar"  :class="[menuAct?'menuAct':'',scrollTop>0?'headBf':'',isHover||isActive?'headBf':'']" @mouseover="handleMouseOver" @mouseout="handleMouseOut">
    <div class="navbar-main">
      <div class="navbar-logo">
        <img class="pl-logo1"  src="./img/logo1.webp">
        <img class="pl-logo" src="./img/logo.webp">
        <img class="phone-logo" src="./img/mobile-logo.webp">
        <img class="phone-logo1" src="./img/mobile-logo1.webp">
      </div>
      <div class="navbar-list">
        <div class="navbar-list-item" v-for="(item,index) in  menusList" :key="index">
          <span class="navbar-list-item-to cur"  :class="[activePath==item.url?'act-list-item-to':'']" @click.stop="toPath(item)">{{ item.title }}</span>
          <div class="navbar-list-item-children" v-if="item.children.length>0">
            <div v-for="(v,i) in item.children" :class="[v.url==actMenuPath?'navbar-list-item-children-hover':'']" :key="i" @click.stop="toPaths(v)">
              {{ v.title }} 
            </div>
          </div>
        </div>
      </div>
      <div class="navbar-search">
        <div class="navbar-search-main">
          <img class="yuyan" :src="scrollTop>0||isHover||isActive?require('./img/icon-yuyan.webp'): require('./img/yuyan.webp') ">
          <div class="title cur">
            <a href="/" target="_blank" >中文</a>
            <div class="title_bot">
              <a href="https://www.camelbatt.com/" target="_blank" >EN</a>
              <a href="http://www.camelpower.com.my" target="_blank" >MY</a>
            </div>
          </div>
          <i class="el-icon-caret-bottom"></i>
          <!-- <img class="xiala" src="./img/xiala.webp"> -->
          <span class="line"></span>
          <img class="search cur" @click="toSearch"  :src="scrollTop>0||isHover||isActive?require('./img/icon-search-bf.webp'): require('./img/search.webp') "  @mouseover="isSearch=true">
          <!-- <input class="Search" v-if="isSearch" type="text" placeholder="请输入" @mouseout="isSearch=false"/> -->
        </div>
      </div>
      <div class="navbar-menu">
        <img class="icon-menu" @mouseover="menuAct=true" src="./img/icon-menu.webp">
        <img class="icon-menu1" @mouseover="menuAct=true" src="./img/icon-menu-b.webp">
      </div>
      <div class="menus">
        <div class="menus-main">
          <div class="menus-top">
            <div class="menus-top-main">
              <img class="yuyan" src="./img/yuyan.webp">
              <div class="title">
                <!-- <a href="/" target="_blank" >中文</a> -->
                <a href="https://www.camelbatt.com/" target="_blank" >EN</a>
                <a href="http://www.camelpower.com.my" target="_blank" >MY</a>
              </div>
              
              <img class="xiala" src="./img/xiala.webp">
            </div>
            <div @click="menuAct=false">                                                             
              <img class="icon-close" src="./img/icon-close.webp">
            </div>
          </div>
          <div class="menus-search" @click="toSearch">
            <img class="icon-search" src="./img/icon-search.webp">
            <!-- <input/> -->
          </div>
          <div class="menus-list">
            <div class="menu-item" v-for="(item,index) in menusList" :key="index" @click="isExpand(item,index)">
              <div class="menu_top" :class="[item.isExpand?'menuTopAct':'']">
                <span class="title1" @click="toPath(item)">{{ item.title }}</span>
                <img class="phone-xiala" src="./img/phone-xiala.webp" v-if="item.children&&item.children.length>0" >
              </div>
              <div :class="[item.isExpand?'menuChildAct':'']" class="menuChild" >
                <div class="menuChild-item" v-for="(v,i) in item.children" :key="i" @click="toPaths(v)">
                  <div class="title2">{{ v.title }}</div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    isActive:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return {
      isSearch:false,
      scrollTop:0,
      isHover:false,
      menuAct:false,
      menusList:[
        {
          title:"首页",
          url:"index",
          children:[],
          isExpand:false,
        },
        {
          title:"产品方案",
          url:"productProgram",
          children:[
            {
              title:"低压铅酸",
              url:"/lowerPlumbic",
            },
            {
              title:"铅酸回收",
              url:"/plumbicRecovery",
            },
            {
              title:"低压锂电",
              url:"/lowLithium",
            },
            {
              title:"锂电再生",
              url:"/lithiumRecovery",
            },
            // {
            //   title:"储能业务",
            //   url:"/energyBusiness",
            // },
            {
              title:"智慧物流",
              url:"/intelligentLogistics",
            },
            {
              title:"用户答疑",
              url:"/userAnswer",
            }
          ],
          isExpand:false,
        },
        {
          title:"智能制造",
          isExpand:false,
          url:"research",
          children:[
            // {
            //   title:"创新研发",
            //   url:"/innovative",
            // },
            {
              title:"智能制造",
              url:"/intelligent",
            },
            {
              title:"体系认证",
              url:"/certification",
            }
          ]
        },
        {
          title:"全球市场",
          url:"GlobalMarket",
          children:[
            {
              title:"国内市场",
              url:"/domestic",
            },
            {
              title:"国际市场",
              url:"/international",
            },
            // {
            //   title:"配套市场",
            //   url:"/supporting",
            // },
            // {
            //   title:"电商业务",
            //   url:"/eCommerce",
            // },
          ],
          isExpand:false,
        },
        {
          title:"新闻中心",
          url:"news",
          children:[
            {
              title:"国内动态",
              url:"/businessMovement",
            },
            {
              title:"国际动态",
              url:"/nationalMovement"
            },
            // {
            //   title:"通知公告",
            //   url:"/notice",
            // },
            // {
            //   title:"专题专栏",
            //   url:"/special",
            // },
            {
              title:"视频专区",
              url:"/videoCenter",
            }
          ],
          isExpand:false,
        },
        {
          title:"可持续发展",
          url:"SustainableDevelopment",
          children:[
            {
              title:"ESG治理",
              url:"/esg",
            },
            {
              title:"绿色发展",
              url:"/greenDevelopment",
            },
            {
              title:"环境公开",
              url:"/environmental",
            },
            {
              title:"信息安全",
              url:"/informationSecurity",
            }
          ],
          isExpand:false,
        },
        {
          title:"投资者关系",
          url:"InvestorProtection",
          children:[
            {
              title:"股票信息",
              url:"http://quote.eastmoney.com/sh601311.html",
              blank:true
            },
            {
              title:"投资者保护",
              url:"/investorProtection",
            },
            {
              title:"投资者问答",
              url:"http://sns.sseinfo.com/searchFullText.do?keyword=601311",
              blank:true
            },
            {
              title:"定期报告",
              url:"/periodicReport"
            },
            {
              title:"信息披露",
              url:"http://www.sse.com.cn/assortment/stock/list/info/company/index.shtml?COMPANY_CODE=601311",
              blank:true
            },
          ],
          isExpand:false,
        },
        {
          title:"关于金沙贵宾会",
          url:"camel",
          children:[
            {
              title:"集团简介",
              url:"/groupProfile",
            },
            {
              title:"企业文化",
              url:"/culture",
            },
            {
              title:"发展战略",
              url:"/strategy",
            },
            {
              title:"发展历程",
              url:"/history",
            },
            {
              title:"品牌定位",
              url:"/positioning",
            },
            {
              title:"荣誉资质",
              url:"/honorary",
            },
           
          ],
          isExpand:false,
        },
        {
          title:"加入我们",
          url:"Join",
          children:[
            {
              title:"人才招聘",
              url:"/talent",
            },
            
          ],
          isExpand:false,
        },
        {
          isExpand:false,
          title:"联系我们",
          url:"connectUs",
          children:[
            {
              title:"联系我们",
              url:"/connect"
            },
            {
              title:"供应链平台",
              url:"/purchase"
            },
            {
              title:"经销商合作",
              url:"/agency"
            }
          ]
        }
      ],
      activePath:'',
      actMenuPath:"",
    }
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    '$route.path': {
      immediate: true,
      handler: function (newVal, oldVal) {
        this.activePath = this.$route.meta.actMenus
        // console.log(this.$route);
        this.actMenuPath=this.$route.path
      }
    },
  },

  mounted(){
    this.handleScroll()
    window.addEventListener('scroll',this.handleScroll)

  },
  beforeDestroy(){
    window.removeEventListener('scroll',this.handleScroll)
  },
  methods:{
    handleScroll(){
      this.scrollTop=document.documentElement.scrollTop || document.body.scrollTop;
    },
    handleMouseOver(){
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      if(isMobile){
        return
      }
      this.isHover=true
    },
    handleMouseOut(){
      this.isHover=false
    },
    isExpand(item,index){
      if(index==0){
        return
      }
      this.menusList[index].isExpand=!item.isExpand
      this.menusList.filter((v,i)=>{
        if(i!=index){
          v.isExpand=false
        }
      })
    },
    toPath(item){
      if(item.url=='index'){
        this.$router.push('/index')
      }
    },
    toPaths(item){
      if(item.blank){
        window.open(item.url)
      }else{
        this.$router.push(item.url)
      }
    },
    toSearch(){
      this.$router.push('/search')
    }
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 767px){
  .headBf{
    background-color: #ffffff;
    .phone-logo{
      display: flex !important;
    }
    .phone-logo1{
      display: none !important; 
    }
    .icon-menu1{
          display: none !important;
        }
        .icon-menu{
          display: flex !important;
        }
  }
  .navbar{
    position: fixed;
    transition: .3s;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 1.24rem;
    display: block;
    // background-color: #ffffff;
    &-main{
      padding: 0.25rem 0.39rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .navbar-logo{
        .pl-logo,.pl-logo1{
          display: none;
        }
        .phone-logo1,.phone-logo{
          width: 2.42rem;
          height: 0.74rem;
        }
        .phone-logo{
          display: none;
        }
        .phone-logo1{
          display: flex;
        }
      }
      .navbar-list,.navbar-search{
        display: none;
      }
      .navbar-menu{
        display: flex;
        align-items: center;
        .icon-menu1{
          display: flex;
        }
        .icon-menu{
          display: none;
        }
        .icon-menu,.icon-menu1{
          width: 0.5rem;
          height: 0.32rem;
        }
      }
    }
  }
  .menus{
    transition-duration: 0.8s;
    height: 0;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 200;
    width: 100%;
    background: #0068B7;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    &-main{
      width: 100%;
    }
    &-top{
      display: flex;
      justify-content: space-between;
      padding: 0 0.38rem;
      height: 1rem;
      &>div{
        display: flex;
        align-items: center;
      }
      
      .yuyan{
        width: 0.38rem;
        height: 0.38rem;
      }
      .title{
        margin: 0 0.1rem;
        position: relative;
        a{
          font-size: 0.22rem;
          margin-right: 0.1rem;
          font-family: FZLanTingHeiS-R-GB;
          font-weight: 400;
          color: #FEFEFE;
          text-decoration: none;
          line-height: 0.3rem;
         
        }
        
      }
      .xiala{}
      .icon-close{
        width: 0.32rem;
        height: 0.32rem;
        &:hover{
          .menus{
            height: 0;
          }
        }
      }
    }
    &-search{
      margin: 0 0.4rem;
      // width: calc(100% - 0.8rem);
      background: #FFFFFF;
      border-radius: 0.12rem;
      height: 0.8rem;
      display: flex;
      position: relative;
      overflow: hidden;
      .icon-search{
        position: absolute;
        left: 0.29rem;
        top:0.19rem;
        width:  0.42rem;
        height: 0.42rem;
      }
      input{
        width: 100%;
        border: none;
        padding:0 0.8rem;
        outline: none;
      }
    }
    &-list{
      margin-top: 0.4rem;
      padding: 0.4rem;
      .menu-item{
        margin-top: 0.4rem;
        padding-bottom: 0.4rem;
        box-sizing: border-box;
        border-bottom: 0.03rem solid #EFF6FF;
        display: flex;
        flex-direction: column;
        .menuTopAct{
          .phone-xiala{

            transform: rotate(-180deg);
          }
        }
        .menu_top{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title1{
            display: flex;
            font-size: 0.3rem;
            font-family: FZLanTingHeiS-R-GB;
            font-weight: 400;
            word-break: keep-all;
            color: #FFFFFF;
          }
          .phone-xiala{
            width: 0.32rem;
            height: 0.14rem;
            transform: all .3s ease-in;
          }
        }
        
        
        .menuChild{
         
          transition-duration: .6s;
          height: 0;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          .menuChild-item{
            display: flex;
            .title2{
              padding-left: 0.6rem;
              font-size: 0.3rem;
              font-family: FZLanTingHeiS-R-GB;
              font-weight: 400;
              color: #FFFFFF;
              margin-bottom: 0.54rem;
            }
          }
        }
        .menuChildAct{
          margin-top: 0.6rem;
          height: 5.6rem !important;
        }
      }
    }
  }
  
  .menuAct{
    .menus{
      height: 100vh;
    }
  }
}

@media screen and (min-width: 768px){
.headBf{
  .pl-logo1{
    display: inline-block !important;
  }
  .pl-logo{
    display: none;
  }
  background-color: #fff;
  .navbar-list-item-to{
    color: #777777 !important;
  }
  .act-list-item-to{
    color: #0068B7 !important;
    &::after{
      // position: absolute;
      // content: '';
      // bottom: 0.21rem;
      // left: 0;
      // height: 0.02rem;
      // background: #0068B7;
      width: 100% !important;
    }
  }
  .navbar-search{
    .title{
      a{
        color: #777777 !important;
      }
    }
    i{
      color: #7777 !important;
    }
    .line{
      background-color: #777777 !important;
    }
  }
}

.menus{
  height: 0;
  display: none;
  overflow: hidden;
}
.navbar{
  position: fixed;
  transition: .3s;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 1rem;
  line-height: 1rem;
  &-main{
    padding: 0 0.99rem;
    display: flex;
    justify-content: space-between;
    .navbar-logo{
      height: 1rem;
      display: flex;
      align-items: center;
      .phone-logo,.phone-logo1{
        display: none;
      }
      .pl-logo1{
        display: none;
      }
      img{
        width: 1.82rem;
        height: auto;
      }
    }
    .navbar-list{
      display: flex;
      &-item{
        display: flex;
        position: relative;
        overflow: hidden;
        &:hover{
          .cur{
            color:#0068B7 !important;
            &::after{
              width: 100%;
            }
          }
        }
        &-to{
          font-size: 0.18rem;
          font-family: FZLanTingHeiS-R-GB;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 1rem;
          text-decoration: none;
          &::after{
              position: absolute;
              content: '';
              bottom: 0.21rem;
              left: 0;
              height: 0.02rem;
              background: #0068B7;
              width: 0;
              transition: all .3s;
            }
          // &:hover{
          //   color:#0068B7 !important;
            
          // }
        }
        &:hover{
          overflow: visible;
          .navbar-list-item-children{
            // border-top:  0.01rem solid #ccc;
            // height: 6rem;
            opacity: 1;
          }
        }
        .navbar-list-item-children{
          opacity: 0;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          // height: 0;
          width: 100%;
          transition: all .3s ease-in-out;
          overflow: hidden;
          position: absolute;
          top: 1rem;
          left: 0;
          z-index: 999;
          justify-content: center;
          background-color: #fff;
          .navbar-list-item-children-hover{
            color: #0068B7 ;
          }
          div{
            display: flex;
            font-size: 0.18rem;
            font-family: FZLanTingHeiS-R-GB;
            font-weight: 400;
            color: #777777;
            // margin-right: 0.43rem;
            line-height: 0.5rem;
            &:hover{
              color: #0068B7 ;
            }
          }
        }
      }

      &-item{
        padding:0 .25rem;
      }
      
    }
    .navbar-search{
      display: flex;
      &-main{
        display: flex;
        align-items: center;
        position: relative;
        .yuyan,.search{
          width: .24rem;
          height: .24rem;
        }
        .title{
          position: relative;
          display: flex;
          margin:0 0.1rem;
          width: 0.5rem;
          justify-content: center;
          a{
            font-size: .14rem;
            font-family: FZLanTingHeiS-R-GB;
            font-weight: 400;
            color: #fff;
            text-decoration: none;
            line-height: 0.3rem;
          }
          .title_bot{
            opacity: 0;
            position: absolute;
            top: 0.3rem;
            left:0;
        
            display: flex;
            width: 0.5rem;
            flex-direction: column;
            text-align: center;
            // transition: .2s ease-in-out;
            a{
              background-color: #fff;
              line-height: 0.3rem;
            }
            a:hover{
              color:#0068B7 !important
            }
          }
          &:hover{
            a{
              color:#777 !important;
            }
            .title_bot{
              opacity: 1;
            }
          }
        }
        i{
          color: #fff;
          font-size: 0.14rem;
        }
        .line{
          width: 1px;
          background-color: #fff;
          display: inline-block;
          height: 0.20rem;
          margin: 0 0.10rem;
        }
        .xiala{
          width: .20rem;
          height: .10rem;
        }
      }
      .Search{
        position: absolute;
        z-index: 10;
        height: 0.3rem;
        font-size: 0.12rem;
        width:calc(100% + 6px) ;
        padding-left: 0.10rem;
        padding-right: 0.25rem;
        background: #fff;
        border: 1px solid #cbcbcb;
        border-radius: 0.25rem;
        right: -4px;
        outline:none;
        // top: 0;
      }
    }
    .navbar-menu,.icon-close{
      display: none;
    }
  }
}
}
</style>